// 3rd Party CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '@mainstem/mainstem-react-app/dist/index.css'
// Local CSS
import './assets/scss/layout.scss'
// React
import { Suspense, useState } from 'react'
// Routing
import { BrowserRouter } from 'react-router-dom'
// Microsoft - Application Insights
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
// 3rd Party - Query String
import * as queryString from 'query-string'
// MainStem - Old Components
import { MainStemBase } from '@mainstem/mainstem-react-app'
// MainStem - Components
import { ToastContainer, useOnFirstLoad } from '@mainstem/react-mainstem'
// Global - API's
import { APIAuthLogout } from 'api/auth/logout'
import { apiAuthCurrentUser } from 'api/auth/current-user'
import { APIAuthCurrentUserAPIResponse } from 'api/auth/current-user/response'
import { apiStatusCheck } from 'api/statusCheck'
// Global - Config
import { HTTP, baseURL } from 'config'
// Global - Context
import { AppContext, AppContextDetails } from 'context'
// Local - Components
import { links } from './components/nav-left/links'
import { navTopUserLinks } from './components/nav-top/links'
import { LayoutImpersonate } from './components/impersonate'
import { InvitationImpersonation } from './components/invitation-impersonation'
import NavLeft from './components/nav-left'
import NavTopButtons from './components/nav-top/buttons'
import NavTopCTA from './components/nav-top/cta'
import NavTopIcons from './components/nav-top/icons'
import NavTopSearch from './components/nav-top/search'
import NavTopAlertBar from './components/nav-top/alert-bar'
import ThemeBold from './components/theme-bold'
import { ErrorWrapper } from './components/error-wrapper'
// Local - Styles
import { GlobalStyle } from './styles'

const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_REACT_APP_APPLICATION_INSIGHTS,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
})

appInsights.loadAppInsights()

const Layout = () => {
  // Set the baseURL in local storage for use in @mainstem/react-mainstem
  window.localStorage.setItem('baseURL', baseURL)

  // Whether or not we have finished checked to see if the user is logged in
  const [checkedForInitialUser, setCheckedForInitialUser] = useState<boolean>(false)
  // The token of the logged in user
  const [userToken, setUserToken] = useState<string>()
  // The details about the logged in user
  const [loggedInUser, setLoggedInUser] = useState<APIAuthCurrentUserAPIResponse | undefined>(undefined)

  // Define our global app context
  const contextValue = {
    loggedInUser,
    setLoggedInUser,
    userToken,
    setUserToken
  } as AppContextDetails

  const handleStatusCheck = () => {
    apiStatusCheck().then((apiResponse) => {
      console.log(apiResponse)
    })
  }

  const handleGetCurrentUser = () => {
    const token = window.localStorage.getItem('mainstem_user_token')
    if (token) {
      apiAuthCurrentUser()
        .then((apiResponseCurrentUser) => {
          if (apiResponseCurrentUser.supplier) {
            setLoggedInUser(apiResponseCurrentUser)
            setUserToken(token)
          } else {
            window.localStorage.removeItem('mainstem_user_token')
          }
          setCheckedForInitialUser(true)
        })
        .catch(() => {
          window.localStorage.removeItem('mainstem_user_token')
        })
    } else {
      setCheckedForInitialUser(true)
    }
  }

  const handleLogin = (token: string) => {
    window.localStorage.setItem('mainstem_user_token', token)
    HTTP.defaults.headers.Authorization = `Bearer ${token}`
    const parsed = queryString.parse(window.location.search)
    if (parsed.redirect) {
      window.location.href = '/' + parsed.redirect
    } else {
      window.location.href = '/'
    }
  }

  const handleLogout = () => {
    APIAuthLogout().then(() => {
      setLoggedInUser(undefined)
    })
  }

  const publicRoutes = [
    {
      path: ['/invitation-impersonation/:supplierUUID/:userUUID/:redirect?'],
      component: <InvitationImpersonation />
    }
  ]

  const appSettings = {
    appName: 'Supplier Core™',
    baseURL,
    checkedForInitialUser,
    forceLogin: true,
    impersonationComponent: <LayoutImpersonate />,
    publicizedRoutes: publicRoutes,
    links: links,
    loggedInUser,
    logo: null,
    navLeft: <NavLeft />,
    navTopAlertBar: <NavTopAlertBar />,
    navTopButtons: <NavTopButtons />,
    navTopCTA: <NavTopCTA />,
    navTopIcons: <NavTopIcons />,
    navTopSearch: <NavTopSearch />,
    navTopUserLinks: navTopUserLinks,
    onLogin: handleLogin,
    onLogout: handleLogout,
    routes: <ErrorWrapper />,
    showNavLeft: true,
    userImage: loggedInUser?.supplier?.logoURL,
    userToken
  }

  // Fires when app first loads
  useOnFirstLoad(() => {
    handleGetCurrentUser()
    handleStatusCheck()
  })

  return (
    <>
      <GlobalStyle />
      <AppContext.Provider value={contextValue}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Suspense fallback={<></>}>
            <ThemeBold />
          </Suspense>
          <BrowserRouter>
            <MainStemBase settings={appSettings} />
            <ToastContainer />
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </AppContext.Provider>
    </>
  )
}

export default withAITracking(reactPlugin, Layout, '', 'hoc-root')
